import React from "react";
import { Link } from "react-router-dom";

const ServiceAreaTwo = () => {
  return (
    <div className="service-area-2 space overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="title-area text-center">
              <span className="sub-title">Our Services</span>
              <h2 className="sec-title">
                Tire Sales & Repairs{" "}
                <img
                  className="title-bg-shape shape-center"
                  src="assets/img/bg/title-bg-shape.png"
                  alt="Penn Tire Exchange"
                />
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/icon/service-icon_1-8.jpg)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img src="assets/img/icon/service-icon_1-1.svg" alt="Penn Tire" />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link to="#">New Tire Sales</Link>
                  </h4>
                  <p className="service-card_text">
                  New tires don't have to break the bank. Penn Tire Exchange, LLC has the best prices on all sizes of new tires from the top brands. New tires are backed by manufacturer warranties.{" "}
                  </p>
                </div>
              </div>
              {/* <Link to="/service-details" className="btn style4">
                Read More <i className="fas fa-arrow-right" />
              </Link> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/icon/service-icon_1-7.jpg)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img src="assets/img/icon/service-icon_1-2.svg" alt="Penn Tire" />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link to="#">Used Tires for Sale</Link>
                  </h4>
                  <p className="service-card_text">
                  Save money with our stock of used tires. Used tires come with a 30 day warranty. Visit us for a FREE estimate.{" "}
                  </p>
                </div>
              </div>
              {/* <Link to="/service-details" className="btn style4">
                Read More <i className="fas fa-arrow-right" />
              </Link> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-card style2">
              <div
                className="service-card_content"
                style={{
                  backgroundImage: "url(assets/img/icon/service-icon_1-9.jpg)",
                }}
              >
                <div>
                  <div className="service-card_icon">
                    <img src="assets/img/icon/service-icon_1-3.svg" alt="Penn Tire" />
                  </div>
                  <h4 className="service-card_title h5">
                    <Link to="#">Tire Repairs</Link>
                  </h4>
                  <p className="service-card_text">
                  Extend the life of your tires with expert repair from Penn Tire Exchange. We offer tire patching and plugging.{" "}
                  </p>
                </div>
              </div>
              {/* <Link to="/service-details" className="btn style4">
                Read More <i className="fas fa-arrow-right" />
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAreaTwo;
