import React from "react";
import { Link } from "react-router-dom";

const HeroTwo = () => {
  return (
    <div
      className="hero-wrapper hero-2"
      id="hero"
      style={{ backgroundImage: "url(assets/img/hero/hero_bg_2_2.jpg)" }}
    >
      <div className="hero-shape2-1">
        <span className="hero-shape2-2" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xxl-6 col-xl-5 col-lg-8">
            <div className="hero-style2">
              <span className="sub-title text-white">Penn Tire Exchange</span>
              <h1 className="hero-title text-white">
                Great{" "}
                <span>
                  <img src="assets/img/hero/hero_shape_2.png" alt="Penn Tire" />
                  Prices
                </span>{" "}
                on New and Used Tires
              </h1>
              <p className="hero-text text-white">
              Tire Warranties | Free Estimates
              </p>
              <div className="btn-group">
                {/* <Link to="/about" className="btn">
                  Learn More
                </Link> */}
                <div className="call-media-wrap">
                  <div className="icon">
                    <img src="assets/img/icon/phone-1.svg" alt="Penn Tire" />
                  </div>
                  <div className="media-body">
                    <h6 className="title text-white">Give us a call:</h6>
                    <h4 className="link">
                      <Link className="text-white" to="tel:7172441990">
                        (717) 244-1990
                      </Link>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroTwo;
